/* Adjusted CSS */
:root {
    --primary-bg-color: #121212; /* Dark background */
    --secondary-bg-color: #222; /* Lighter dark shade for elements */
    --accent-color: #9d50bb; /* Vibrant accent color */
    --text-color: #ffffff; /* Primary text color */
    --link-color: #bb86fc; /* Light purple for links to stand out */
    --hover-bg-color: #2a2a2a; /* Hover state background */
}

body {
    margin: 0;
    padding: 20px;
    font-family: 'Roboto', sans-serif;
    background-color: var(--primary-bg-color); /* Dark background for eye comfort */
    /* color: #ffffff; */
    line-height: 1.4; /* Tighter line height for a more compact look */
}

.table {
    width: 100%;
    margin: 20px auto;
    border-collapse: collapse;
    font-size: 0.9em; /* Slightly smaller font size for compactness */
    background-color: var(--secondary-bg-color);
    color: var(--text-color);
    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.2); /* Subtle shadow for depth */
    border-radius: 8px; /* Rounded corners for modern look */
    overflow: hidden; /* Ensures rounded corners clip content */
}

thead {
    background: linear-gradient(45deg, #6e48aa, #9d50bb);
    color: #ffffff;
    text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.2); /* Soft text shadow */
}

th, td {
    padding: 8px 9px; /* Reduced padding for more compact rows */
    text-align: left;
    transition: all 0.3s ease; /* Smooth transition for hover effects */
}

td {
    text-align: center;
    padding: 8px 1px; /* Reduced padding for more compact rows */
}


/* Enhancing links within the table for better visibility */
.table a {
    color: var(--link-color);/* Light purple for better contrast and visibility */
    text-decoration: none; /* Optional: Removes underline from links */
    transition: color 0.3s ease; /* Smooth transition for hover */
}

.table a:hover {
    color: #D0B3FF; /* Lighter purple on hover for interactivity */
}

tbody tr {
    border-bottom: thin solid #444; /* Subtle separator */
}

tbody tr:hover {
    background-color: #2a2a2a; /* Lighter for hover indication */
}

.first_column_div {
    display: flex;
    align-items: left;
    text-align: left;
    font-weight: bold;
}

img {
    margin-right: 10px;
    border-radius: 4px; /* Rounded corners for images */
    width: auto; /* Maintain aspect ratio */
    height: 40px; /* Reduced height for compact alignment */
}

.pagination {
    color: var(--text-color);
    align-items: center;
    padding: 10px;
    display: flex;
    justify-content: flex-end;
    background: #1e1e1e;
}

.pagination button, .pagination select {
    margin-left: 2px;
    margin-left: 2px;
    padding: 5px 10px;
    background: #333;
    color: #ddd;
    border: 1px solid #444;
    border-radius: 4px;
    cursor: pointer;
    transition: background-color 0.3s ease;
}

.pagination button:hover, .pagination select:hover {
    background: #454545; /* Hover effect */
}

.pagination button:disabled {
    background: #2a2a2a;
    cursor: not-allowed;
    opacity: 0.5;
}

.main-container {
    display: flex;
    gap: 4px; /* Optional spacing between components*/
}

.contact-page {
    display: flex;
    flex-direction: column;
    gap: 4px; /* Optional spacing between components*/
}
.footer {
    display: flex;
    justify-content: space-between;
    align-items: center;
    left: 0;
    bottom: 0;
    width: 100%; /* Ensures it stretches across the full width of the page */
    padding: 10px; /* Add some padding for aesthetics */
    box-sizing: border-box;
    background: #1e1e1e;
    border-radius: 8px;
    border-top: 1px solid #444; /* Separator */
}
.footer-links {
    display: flex;
    align-items: center;
}
.footer-links a {
    display: flex;
    color: var(--link-color);
    align-items: center;
}

.contact-page {
    display: flex; /* Use flexbox for layout */
    justify-content: center; /* Center children horizontally */
    align-items: center; /* Center children vertically */
    height: 60vh; /* Make div take up the full viewport height */
    color: var(--link-color);/* Light purple for better contrast and visibility */
    font-weight: bold;
    text-align: center; /* Ensure text is centered */
}

/* Styles for the <a> tag to inherit color and remove underlines */
.contact-page a {
    font-size: 4vw;
    color: inherit; /* Make the link color the same as its parent */
    text-decoration: none; /* Optional: removes the underline from links */
}