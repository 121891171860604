

/* filter-panel */

.filter-panel {
  position: relative; /* Adjust positioning as needed (fixed, absolute) */
  background-color: var(--primary-bg-color);
  padding: 2px;
  border-radius: 5px;
  box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.3);
}

.filter-panel button {
  background: #333;
  font-weight: bold;
  color: #ddd;
  padding: 5px 5px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

/* filter-panel__content */
.filter-panel__content {
  margin-top: 20px;
}

button.collapse-button {
  background: #333;
  font-weight: normal;
  padding: 2px 5px;
  margin-bottom: 3px;
}
.collapse-button{
  background: none;
  border: none;
  text-align: left;
  /* Add styles to make it look like a clickable label */
}

.filter-field {
  margin-bottom: 10px;
}

.filter-input {
  margin-right: 1px;
  margin-left: 3px;
  width: 50%;
  background-color: var(--secondary-bg-color);
  color: var(--text-color);
  /* Styles to make it look greyed out */
}

.clear-button {
  margin-left: 1px;
  /* Styles to make it look like a solid color with (x) */
}


.filter-field label {
  display: block;
  margin-bottom: 5px;
}

/* number-filter */
.number-filter { 
  display: flex;
  gap: 10px; /* Space between input fields */
}

.number-filter input[type="number"] {
  flex: 1; /* Inputs grow to fill width */
  padding: 8px;
  border: 1px solid #ccc;
  border-radius: 4px;
}

.range-info {
  font-size: 0.9em;
  color: #666; /* Dimmed text color */
}

/* filter-actions */
.filter-actions {
  display: flex;
  justify-content: flex-end; /* Or space-between, etc. */
  margin-top: 15px; 
}

.filter-actions button {
  margin-left: 10px; 
}
select {
  background-color: var(--secondary-bg-color);
  color: var(--text-color);
}